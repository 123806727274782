import React from 'react'

import PropTypes from 'prop-types'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'

import styles from './VideoEmbed.module.scss'

import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

/**
 * Returns an iframe with an embedded video
 * @param youtubeId {string} Example "CvLV493BeP0" from youtube.com/watch?v=CvLV493BeP0
 * @param videoTitle {string} Title for the video
 * @param extraClass {string} Extra class for wrapper
 * @param fallbackThumbnail {string} Optional thumbnail if maxresdefault doesn't exist
 * @return {React.Component} Returns responsive 16:9 iframe inside block container
 * @constructor
 */
const VideoEmbed = ({
  youtubeId,
  videoTitle,
  extraClass,
  fallbackThumbnail,
}: any) => {
  const youtubeEmbedProps = {
    id: youtubeId,
    title: videoTitle,
    cookie: true,
    playlist: false,
    params: 'modestbranding=1&rel=0',
    poster: 'maxresdefault',
  }

  if (fallbackThumbnail) {
    Object.assign(youtubeEmbedProps, {
      thumbnail: fallbackThumbnail,
    })
  }

  return (
    <div
      className={
        extraClass ? [extraClass, styles.wrapper].join(' ') : styles.wrapper
      }
    >
      {/* @ts-ignore */}
      <LiteYouTubeEmbed {...youtubeEmbedProps} />
    </div>
  )
}

VideoEmbed.propTypes = {
  youtubeId: PropTypes.string.isRequired,
  videoTitle: PropTypes.string,
  extraClass: PropTypes.string,
  fallbackThumbnail: PropTypes.string,
}

export default VideoEmbed
