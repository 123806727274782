import React from 'react'

import Highlight, { HighlightProps } from './Highlight'
import HightlightWillsShort from './variants/Wills/HighlightWillsShort'
import HightlightWills from './variants/Wills/HightlightWills'

const HighlightWrapper = ({ moduleData }: HighlightProps) => {
  if (moduleData.variant === 'wills') {
    return <HightlightWills clickthroughUrl={moduleData.clickthroughUrl} />
  }
  if (moduleData.variant === 'willsShort') {
    return <HightlightWillsShort />
  }

  return <Highlight moduleData={moduleData} />
}

export default HighlightWrapper
