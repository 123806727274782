import {
  WILLS_AND_TRUST_PRICING_FLAG,
  WillsAndTrustPricingVariants,
} from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useWillsPricing = () => {
  const { isLoading, variation } = useDecisionForFlag({
    name: WILLS_AND_TRUST_PRICING_FLAG.EXPERIMENT_KEY,
    fallbackVariation: WILLS_AND_TRUST_PRICING_FLAG.VARIATIONS.CONTROL,
  })

  return {
    isLoading,
    variation: variation as WillsAndTrustPricingVariants,
  }
}
